import React, {useState} from "react";

// material-ui
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

// 旅程のページ内遷移用
import { LinkListModal, CouponModal } from "./../../modal";

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

// ダイヤログインポート
import { scheduleListModalData } from "../../../types/planSelectConfirm";
import {useCouponResponse} from "../../../types/coupons";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import {getCoupon} from "../../../utils/coupons";

type Props = {
  scheduleListData: scheduleListModalData;
  setLoading: (show: boolean) => void
  planSelectFormData: PlanSelectFormData;
  handleSetCoupon: (coupon: useCouponResponse) => void;
  handleRemoveCoupon: (couponCode: string) => void;
  isShowClearCoupon: boolean;
  handleShowClearCoupon: (show: boolean) => void;
};

const SectionCoupon: React.FC<Props> = ({ scheduleListData, setLoading, planSelectFormData, handleSetCoupon, handleRemoveCoupon, isShowClearCoupon, handleShowClearCoupon }) => {
  const [linkListModal, setLinkListModal] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [result, setResult] = React.useState("");
  const [resultCoupon, setResultCoupon] = React.useState<useCouponResponse>();
  const [couponCode, setCouponCode] = React.useState<string>("");

  //プチモーダル：ページ内遷移用
  const handleLinkListModal = () => {
    setLinkListModal(!linkListModal);
  };

  const handleOpenModal = (resultValue: string) => {
    setResult(resultValue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleShowClearCoupon(false);
  };

  const [controller, seController] = useState(new AbortController())
  const handleCouponApply = async () => {
    if (couponCode === "") {
      return;
    }
    setLoading(true);
    
    const response = await getCoupon(controller, planSelectFormData, couponCode);
    setLoading(false);
    if (response) {
      setResultCoupon({...response});
      if (response.status === 200) {
        handleSetCoupon({...response});
      }
    }
    setCouponCode("")
    setIsModalOpen(true);

  }

  return (
    <>
      {planSelectFormData.isCoupons && (
        <section>
          {/* クーポンコードの入力 */}
          <div className="wrap-content-900">
            <h2 className="heading-2 mb-16">クーポンコードの入力</h2>
            <div className="sticky-top-list">
              <div id="coupon" className="sticky-top-list-item">
                {planSelectFormData.coupons.length > 0 ? (
                  <>
                    {/* クーポン適用後の表示 */}
                    <Accordion className="accordion-schedule" expanded={true}>
                      <AccordionSummary
                        className="accordion-schedule-heading"
                        onClick={handleLinkListModal}
                      >
                        クーポンコード
                      </AccordionSummary>
                      <AccordionDetails className="accordion-schedule-content">
                        <div className="box-coupon-code">
                          <p className="mb-16 w-100per">
                            以下のクーポンが適用されています。
                          </p>
                          <div className="box-coupon-code-applied box-light-gray">
                            <p className="box-coupon-code-applied-title">
                              {planSelectFormData.coupons[0].couponName}
                            </p>
                            <button className="box-coupon-code-button button-border-medium" onClick={() => {
                              handleRemoveCoupon(planSelectFormData.coupons[0].couponCode)
                            }}>
                              適用を取り下げる
                            </button>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Accordion className="accordion-schedule" expanded={true}>
                      <AccordionSummary
                        className="accordion-schedule-heading"
                        onClick={handleLinkListModal}
                      >
                        クーポンコード
                      </AccordionSummary>
                      <AccordionDetails className="accordion-schedule-content">
                        <div className="box-coupon-code">
                          <p className="mb-16 w-100per">
                            クーポンコードをお持ちの方は以下に入力してください。
                          </p>
                          <input
                            type="text"
                            placeholder="例：ABC1234DF56"
                            className="form-input-text"
                            value={couponCode}
                            defaultValue={couponCode}
                            onChange={(event) => {
                              setCouponCode(event.target.value)
                            }}
                          />
                          <span className="box-coupon-code-note mb-16 mb-0-md mt-8-md">
                      半角英数字でご入力ください。
                    </span>

                          <button
                            className="box-coupon-code-button button-medium-orange"
                            onClick={() => {
                              handleCouponApply()
                            }}
                          >
                            適用する
                          </button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}

              </div>
            </div>
          </div>
        </section>
      )}

      {/* ここに作成したモーダルを追加する */}
      {/* リンクリスト */}
      <LinkListModal
        isShow={linkListModal}
        scheduleListModalData={scheduleListData}
        callback={handleLinkListModal}
      />

      <CouponModal
        isShow={isModalOpen}
        isShowClearCoupon={isShowClearCoupon}
        result={result}
        resultCoupon={resultCoupon}
        callback={handleCloseModal}
        planSelectFormData={planSelectFormData}
      />
    </>
  );
};

export default SectionCoupon;
