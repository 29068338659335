import {carRentalAnshinOptionType, carRentalChildSeatOptionType} from "../types/carRental";

/**
 * 一台あたりに設定できるチャイルドシートの数
 */
export const perSheetMaxNum = 3;

export const enabledCarRentalStatus = [
  "enabled",
  "chitose"
];

export const carRentalOfficeCodes = [
  {
    label: '臨空豊崎営業所(那覇空港）',
    code: "toyosaki",
  },
  {
    label: 'DFS営業所（おもろまちモノレール駅）',
    code: "dfs",
  },
  // @memo 非表示（店舗としては存在しているが国内個人旅行客は基本受けていないため）
  // {
  //   label: '赤嶺店',
  //   code: "akamine-walk-in",
  // },
  {
    label: '新石垣空港',
    code: "ishigaki",
  },
  {
    label: '宮古空港',
    code: "miyako",
  },
]

export const chitoseCarRentalOfficeCodes = [
  {
    label: '千歳空港営業所（スズキレンタカー北海道）',
    code: "chitose",
  },
]

export const carRentalBorrowingTimeOptions = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
];

export const carRentalReturnTimeOptions = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
];

export const chitoseCarRentalBorrowingTimeOptions = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
];

export const chitoseCarRentalReturnTimeOptions = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
];

export const availableCarRentalAirCodes = [
  "ANA",
  "SNA",
  "JAL",
  "JTA",
  "RAC",
]

export const carRentalChildSeatOptions: carRentalChildSeatOptionType[] = [
  {
    img: require("../img/share/rentacar/rentacar_img_Seat_01.png").default,
    title: "ベビーシート",
    heading: "乳幼児ベビーシート",
    target_age: "新生児から生後1歳位まで",
    weight: "10kg位まで",
    height: "75cm位まで",
    code: 'baby_seat',
  },
  {
    img: require("../img/share/rentacar/rentacar_img_Seat_02.png").default,
    title: "チャイルドシート",
    heading: "幼児用チャイルドシート",
    target_age: "生後6ヶ月から4歳位まで",
    weight: "18kg位まで",
    height: "100cm位まで",
    code: 'child_seat',
  },
  {
    img: require("../img/share/rentacar/rentacar_img_Seat_03.png").default,
    title: "ジュニアシート",
    heading: "学童用ジュニアシート",
    target_age: "4歳から6歳位まで",
    weight: "32kg位まで",
    height: "135cm位まで",
    code: 'junior_seat',
  },
]

export const carRentalAnshinOption = {
  img: require("../img/share/rentacar/rentacar_img_anshin_basic.png").default,
  title: "ベーシック",
  heading: "安心パック",
  name: "安心パック",
  label: "安心パック/ベーシック",
  code: 'anshin',
}

export const carRentalAnshinPremiumOption = {
  img: require("../img/share/rentacar/rentacar_img_anshin_premium.png").default,
  title: "プレミアム",
  name: "プレミアム安心パック",
  heading: "安心パック",
  label: "安心パック/プレミアム",
  code: 'anshin_premium',
}

export const carRentalAnshinOptions: carRentalAnshinOptionType[] = [
  carRentalAnshinOption,
  carRentalAnshinPremiumOption
]