import React, {useEffect} from "react";

//モーダルをインポート
import { 
  ComprehensiveOptionDetailsModal,
} from "./../../modal"

//scss
import "../../../scss/common.scss";
import "../../../scss/component.scss";

import { IActivity } from "../../../types/Activity";
import LoadingOverlay from 'react-loading-overlay-ts';
import {PlanSearch} from "../../../types/Search";
import {
  checkActivityStockExist,
  getPlanAgeConfig, getPlanConfig,
  getPlanPeopleNum,
  getPlanStocks,
  isPlanPriceType,
  isPlanTimeStocks
} from "../../../utils/plan";
import {dateFormat} from "../../../utils/convert";
import {PlanSelectFormData} from "../../../types/planSelectForm";
import axios from "axios";
import SearchNotFound from "../../SearchNotFound";

type Props = {
    activityStockExist : boolean,
    activities: IActivity[]
    isLoading: boolean,
    searchParams: PlanSearch,
    setActivities: ( avts: IActivity[] ) => void,
    planSelectFormData: PlanSelectFormData
}

const SectionPlan: React.FC<Props> = ({
  activityStockExist,
  activities,
  searchParams,
  isLoading,
  setActivities,
  planSelectFormData
}) => {

  const [selectDate, setSelectDate] = React.useState("");

  const [images, setImages] = React.useState<string[]>();

  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  //モーダル初期表示ページを設定
  const [step, setStep] = React.useState(1);
  const stepHandle = (step: number) => {
    setStep(step)
  }
  
  const [loading, setLoading] = React.useState(true);
  const [activityData, setActivityData] = React.useState<IActivity[]>(activities);
  const [activityStocks, setActivityStocks] = React.useState();
  
  
  // 変更確認を選んだアクティビティ
  const [currentActivity, setActivity] = React.useState<IActivity>();

  // モーダルのステータスを追加する
  const [comprehensiveOptionDetailsModal1, setComprehensiveOptionDetailsModal1] = React.useState(false);
  const [comprehensiveOptionDetailsModal2, setComprehensiveOptionDetailsModal2] = React.useState(false);

  // モーダルのhandleを追加する
  const handleComprehensiveOptionDetailsModal1 = (data:IActivity) => {
    setStep(1)
    setComprehensiveOptionDetailsModal1(!comprehensiveOptionDetailsModal1)
    setActivity(data)
  }

  const handleComprehensiveOptionDetailsModal2 = (data:IActivity) => {
    setStep(2)
    setComprehensiveOptionDetailsModal2(!comprehensiveOptionDetailsModal2)
    setActivity(data)
  }

  const selectedCallback = (data:IActivity | undefined) => {
    setLoading(false);
    setComprehensiveOptionDetailsModal1(false);
    setComprehensiveOptionDetailsModal2(false);

    if(data) {
      // 一覧を更新する
      const activityIndex = activities.findIndex((activityData) => activityData?.plan_info.plan_code === data.plan_info.plan_code)
      const planConfig = getPlanConfig(planSelectFormData.sellerProduct?.comprehensiveItems, data.plan_info.plan_code);
      activities[activityIndex].active = true;
      activities[activityIndex].date = data.date;
      activities[activityIndex].time = data.time;
      activities[activityIndex].time_code = data.time_code;
      activities[activityIndex].price_list = data.price_list;
      if (planConfig) {
        activities[activityIndex].oldmin = planConfig.oldmin;
        activities[activityIndex].oldmax = planConfig.oldmax;
        activities[activityIndex].priceType = planConfig.group;
      }
      setSelectDate(dateFormat(data.date, "YYYY年MM月DD日"));
      setActivityData(activities);
      setActivities([...activities]);
    }
  }
  
  useEffect(() => {
    const activitiesData:IActivity[] = activities;
    if (loading && activities !== undefined && activitiesData.length > 0) {
      setLoading(false);
      ( async () => {
        for (const [index, activity] of activitiesData.entries()) {
          activitiesData[index].loading = true;
          const planCode = activity.plan_info.plan_code;
          const planStocks = await getPlanStocks(searchParams, planCode);
          if (planStocks.stock) {
            
            activitiesData[index].stocks = planStocks.detail;
            const isPlanPrice = isPlanPriceType(planSelectFormData, activity);
            if (!isPlanPrice || !isPlanTimeStocks(planSelectFormData, activity, planStocks)) {
              activitiesData[index].stock = false;
            } else {
              activitiesData[index].stock = planStocks.stock;
            }
          }
          activitiesData[index].loading = false;
          setActivityData([...activitiesData]);
        }
        setActivities([...activitiesData]);
        
        setLoading(false);
      })();
    }
    
  },[activities, loading])
  

  //------------------------------------------------------------------
  return (
    <>
      <section>
        {!loading && !activityStockExist
          ? <><SearchNotFound></SearchNotFound></>
          : <>
            <div className="wrap-content-900">
              <h2 className="heading-2 mb-24-md">基本プランの日程選択</h2>
              <p className="fz-14 fz-16-md fw-b mb-10 mb-20-md">
                旅行代金には下記の基本プランの料金が含まれています。まずは、プランの日程を確定してください。
              </p>

              <div className="box-plan-bt_gray">
                <LoadingOverlay active={loading} spinner>
                  <ul className="box-plan-bt_gray-list">
                    {
                      loading ? <div className="pb-100"></div>
                      : activityData.length > 0 ?
                        activityData.map((data: IActivity, index: any) => (
                          <li key={"boxplan"+index} className="box-plan-bt_gray-list-item">
                            <div className="box-plan-bt_gray-list-item-content">
                              <div className="box-plan-bt_gray-list-item-content-description">
                                <div className="box-plan-bt_gray-list-item-content-description-thumbnail large">
                                  <img src={data.plan_info.image_list[0].image_url} alt="" />
                                </div>
                                <div className="box-plan-bt_gray-list-item-content-description-text">
                                  <p className="mb-5">
                                    <span className="label-orange">
                                      <i className="icon-flag-orange mr-5"></i>プラン{index + 1}
                                    </span>
                                  </p>
                                  <p className="fz-16 fw-b lh-1_4">{data?.plan_info?.plan_name}</p>
                                </div>
                              </div>

                                  {
                                    data.active === true ? (
                                      <>
                                        <div className="box-plan-bt_gray-list-item-content-status">
                                          <div className="d-f d-b-md jc-sb ai-c fx-1">
                                            <p className="mb-10-md ta-c fz-14 fw-b">
                                              {selectDate} <br className="d-n d-b-md" />
                                              {data.time && data.time !== 'no_settime' && (<>{data.time}</>)}
                                            </p>
                                            {data.stock && (
                                              <p className="pl-2-md pr-2-md">
                                                <button
                                                  className="button-border-medium w-100per"
                                                  onClick={() => handleComprehensiveOptionDetailsModal2(data)}
                                                >
                                                  変更する
                                                </button>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )
                                    : data.stock ? (
                                      <div className="box-plan-bt_gray-list-item-content-status">
                                        <div className="d-f d-b-md jc-sb ai-c fx-1">
                                          <p className="pl-2-md pr-2-md fx-1">
                                            <button
                                              onClick={() => handleComprehensiveOptionDetailsModal1(data)}
                                              className="button-medium-orange padding-large w-100per"
                                            >
                                              日程を選択する
                                            </button>
                                          </p>
                                        </div>
                                      </div>
                                    )
                                    : data.loading || data.loading === undefined ?
                                      <LoadingOverlay active={true} spinner>
                                      <div className="box-plan-bt_gray-list-item-content-status">
                                        <div className="d-f d-b-md jc-sb ai-c fx-1">
                                        </div>
                                      </div>
                                      </LoadingOverlay>
                                    : (
                                        <div className="box-plan-bt_gray-list-item-content-status">
                                          <div className="d-f d-b-md jc-sb ai-c fx-1">
                                            <span className="button-medium-disabled w-100per">受付終了</span>
                                          </div>
                                        </div>
                                      )
                                  }
                            </div>
                          </li>
                        ))
                      : "基本プランが見つかりませんでした。"
                    }
                  </ul>
                </LoadingOverlay>
              </div>
            </div>
          </>
        }
      </section>
      
      {/* STEP1 */}
      <ComprehensiveOptionDetailsModal planSelectFormData={planSelectFormData} activity={currentActivity} stepHandler={stepHandle} defaultStep={step} isShow={comprehensiveOptionDetailsModal1} callback={selectedCallback} />
      {/* STEP2 */}
      <ComprehensiveOptionDetailsModal planSelectFormData={planSelectFormData} activity={currentActivity} stepHandler={stepHandle} defaultStep={step} isShow={comprehensiveOptionDetailsModal2} callback={selectedCallback} />
    </>
  );
};

export default SectionPlan;
