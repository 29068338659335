import {hotelTagImage, hotelTypeCode, okinawaAreaCodeLabel, roomTypeCode} from "../config/hotel";
import {sellerInfo} from "../types/sller";
import {PlanSearch, roomDetailChild} from "../types/Search";
import {
  hotelList,
  roomData,
  searchHotelParams,
  searchHotelRoomParams,
  stayDetailType,
  hotelData,
  roomSearchDetail,
  hotelPlanDetail,
  updateHotel,
  stayDetail,
  selectedHotel,
  HotelAllRoomSearchResponse,
  HotelAllSearchResponse,
  searchHotelRoomsParams, stayDetailList, roomDataPlanType, selectHotelDetail, hotelSearchQuery
} from "../types/Hotel";
import {apiClient, signalApiClient} from "./apiClient";
import {rageDiffDate, toCircled} from "./convert";
import {planData, PlanSelectFormData, PlanSelectFromHotelType} from "../types/planSelectForm";
import {diffBasicPrice, getBasicTotalPrice} from "./price";
import _ from "lodash";
import {roomTypeCode as roomTypeCodeList} from "../config/hotel";
import { animateScroll as scroll } from 'react-scroll';

export const getHotel = async (
  controller: AbortController,
  searchQuery: hotelSearchQuery) => {

  const hotelSearchParams = getHotelSearchParams(searchQuery);
  try {
    const response = await signalApiClient(controller).post("/hotel/hotel-all-search", JSON.stringify(hotelSearchParams));
    const hotels: HotelAllSearchResponse = await response.data;
    if (hotels) {
      return hotels;
    }
    
  } catch (e) {
    console.error(e);
  }
  
  return undefined
}


export const checkHotel = async (searchQuery: hotelSearchQuery) => {
  try {
    const params = getHotelSearchParams(searchQuery);
    params.params.get_limit = 1;
    const response = await apiClient.post("/hotel/hotel-search", params);
    const result = await response.data;
    if (result.success) {
      return true;
    }
    
  } catch (e) {
    console.error(e);
  }
  
  return false;
}

/**
 * ホテル検索
 * @param controller : AbortController
 * @param searchQuery : hotelSearchQuery
 * @param limit : number
 */
export const hotelSearch = async (controller: AbortController, searchQuery: hotelSearchQuery, limit: number):Promise<hotelList[]> => {
  try {
    const params = await getHotelSearchParams(searchQuery);
    params.params.get_limit = limit;
    const response = await signalApiClient(controller).post("/hotel/hotel-search", JSON.stringify(params));
    const result = await response.data;
    if (result.success && response.data.response.hotel_list) {
      return response.data.response.hotel_list as hotelList[];
    }
    
  } catch (e) {
    console.error(e);
  }
  
  return [] as hotelList[];
}

/**
 * ホテル全検索
 * @param controller : AbortController
 * @param searchQuery : hotelSearchQuery
 */
export const hotelsAllSearch = async (
  controller: AbortController,
  searchQuery: hotelSearchQuery
) => {
  try {
    if (searchQuery.target && searchQuery.code) {
      const params = getHotelSearchParams(searchQuery);
      const response = await signalApiClient(controller).post("/hotel/hotel-all-search", params);
      return response.data as HotelAllSearchResponse;
    }
  } catch (e) {
    console.error(e);
  }
  return {} as HotelAllSearchResponse;
}

/**
 * ホテル詳細検索
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 */
export const hotelsDetailSearch = async (areaCode: string, searchParams: PlanSearch, hotelCode: string|undefined): Promise<hotelData|undefined> => {
  try {
    if (hotelCode) {
      const params = getHotelDetailSearchParms(areaCode, hotelCode, searchParams);
      const response = await apiClient.post("/hotel/hotel-detail", params);
      const result = await response.data;
      if (result.success) {
        return result.response as hotelData;
      }
    }
  } catch (e) {
    console.error(e);
  }
  
  return undefined;
}

/**
 * ホテル部屋一括検索
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 */
export const hotelRoomsSearch = async (searchParams: PlanSearch, hotelCode: string): Promise<HotelAllRoomSearchResponse|undefined> => {
  try {
      const params = getHotelRoomsSearchParms(hotelCode, searchParams);
      const response = await apiClient.post("/hotel/hotel-all-room", {params: params});
      const result = await response.data;
      if (result.status === 200) {
        return result.hotelDetail as HotelAllRoomSearchResponse;
      }
  } catch (e) {
    console.error(e);
  }
  return undefined;
}

/**
 * ルーム詳細取得
 * @param areaCode
 * @param searchParams
 * @param hotelCode
 */
export const hotelRoomSearch = async (controller: AbortController, areaCode: string, searchParams: PlanSearch, hotelCode: string, roomCode: string, planCode: string | undefined): Promise<roomSearchDetail|undefined> => {
  try {
    if (planCode) {
      const params = getHotelRoomSearchParms(areaCode, hotelCode, searchParams);
      params.hotel_code = hotelCode;
      params.room_code = roomCode;
      params.plan_code = planCode
      const response = await signalApiClient(controller).post("/hotel/hotel-room", {params: params});
      return  response.data as roomSearchDetail;
    }
  } catch (e) {
    console.error(e);
  }
  
  return undefined;
}

/**
 * ホテルタイプのコードからラベルを取得
 * @param code
 */
export const getHotelTypeLabel = (code: string) => {
  if ( hotelTypeCode[code] !== undefined) {
    return hotelTypeCode[code];
  }

  return "";
}

/**
 * ホテルエリアラベル
 * @param code
 */
export const hotelAreaCodeLabel = (code: string) => {
  if (okinawaAreaCodeLabel[code]) {
    return okinawaAreaCodeLabel[code];
  }
  return code;
}

/**
 * ホテルタイプラベル
 * @param code
 */
export const hotelTypeCodeLabel = (code: string) => {
  if (hotelTypeCode[code]) {
    return hotelTypeCode[code];
  }
  return code;
}

/**
 * ホテル部屋タイプコードからラベルを取得
 * @param code
 */
export const getRoomTypeLabel = (code: string) => {
  if ( roomTypeCode[code] !== undefined) {
    return roomTypeCode[code];
  }

  return "";
  
}

/**
 * ホテルタグアイコンイメージURL取得
 * @param tagName
 */
export const hotelTagImageName = (tagName:string) => {
  if (hotelTagImage[tagName]) {
    return hotelTagImage[tagName];
  }

  return undefined;
}

/**
 * ホテル予約人数ラベル
 * @param roomDetail
 * @param rooms
 */
export const getHotelRoomPeopleLabel = (roomDetail:any, rooms: number|undefined) => {
  let room = Number(rooms)?? 0;
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  const total = adult + child;
  
  
  return `${room}室・${total}名（大人${adult}/子供${child}）`;
}

/**
 * ホテル検索セクション人数ラベル
 * @param roomDetail
 */
export const getHotelSearchRoomPeopleLabel = (roomDetail:any) => {
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  const total = adult + child;
  
  
  return `${total}名（大人${adult}/子供${child}）`;
}

/**
 * 部屋ごとの人数の割り当てコンテンツ表示取得
 * @param roomDetail
 */
export const getHotelSearchRoomPeopleConfirmLabel = (roomDetail:any) => {
  let adult = 0;
  let child = 0;
  if (roomDetail !== undefined && roomDetail.length > 0) {
    roomDetail.forEach((room:any) => {
      adult += Number(room.adult)?? 0;
      if (room?.child !== undefined && room.child.length > 0) {
        child += room.child.length;
      }
    })
  }
  const total = adult + child;
  
  return `大人${adult}名/子供${child}名`;
}

/**
 * ホテル一覧検索用パラメータデータ取得
 * @param searchQuery
 */
export const getHotelSearchParams = (searchQuery: hotelSearchQuery) => {
  const peopleNum =  getHotelPeopleNum(searchQuery.query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  
  const params:searchHotelParams = {
    "searchType": "",
    "params": {
      "area_code": searchQuery.area_code,
      "check_in": searchQuery.query?.checkin,
      "check_out": searchQuery.query?.checkout,
      "count_adults": peopleNum[countIndex].adult,
      "count_rooms": searchQuery.query.rooms,
      "search_sort": searchQuery.search_sort,
      "get_limit": 10,
    }
  };
    
  if (peopleNum[countIndex].children_a > 0) {
    params.params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.params.count_children_f = peopleNum[countIndex].children_f
  }
  
  if (searchQuery.target === "エリア指定") {
    const codes = searchQuery.code.split(',');
    params.searchType = "area";
    params.params.search_keyword_type = "area";
    params.params.search_keyword_mode = 2;
    if (codes.length > 1) {
      params.params.search_keyword_mode = 2;
    }
    params.params.search_keyword_code = searchQuery.code;
    
  } else if (searchQuery.target === "ホテル指定") {
    params.searchType = searchQuery.search_sort === 0 ? "recommend" : "hotel";
    params.params.hotel_code = searchQuery.code;
  }
  
  if (searchQuery.search_keyword_code !== 'all' && searchQuery.search_keyword_type && searchQuery.search_keyword_mode) {
    params.params.search_keyword_code = searchQuery.search_keyword_code;
    params.params.search_keyword_type = searchQuery.search_keyword_type;
    params.params.search_keyword_mode = searchQuery.search_keyword_mode;
  }
  
  if (searchQuery.search_hotel_type_code !== 'all') {
    params.params.search_hotel_type_code = searchQuery.search_hotel_type_code;
  }

  params.params.get_page = searchQuery.paged ?? 1; // 取得するページを指定
  params.params.search_stock = 2; // 在庫状況(1: すべて、2: 在庫ありのみ)
  
  return params;
}

/**
 * プランを絞り込みして並び替える(トップ用)
 * @param hotelAllData
 * @param hotelAreas
 * @param hotelTypes
 * @returns
 */
export const filterHotels = (
  hotelAllData: HotelAllSearchResponse | undefined,
  hotelAreas: string[],
  hotelTypes: string[],
) => {
  if (hotelAllData === undefined) {
    return undefined;
  }
  const _hotelAllData: HotelAllSearchResponse = _.cloneDeep(hotelAllData);
  if (_hotelAllData && _hotelAllData?.hotelList.length > 0) {
    /**
     * `エリア`と`ホテルタイプ`のAND検索
     */
    const hotelList = _hotelAllData?.hotelList.map((hotelData: selectHotelDetail) => {
      const _hotelData = _.cloneDeep(hotelData);

      // `エリア`で絞り込む(OR検索)
      if (hotelAreas.length > 0) {
        if (!hotelAreas.includes(_hotelData.sub_area_code)) {
          return false;
        }
      }

      // `ホテルタイプ`で絞り込む(OR検索)
      if (hotelTypes.length > 0) {
        const filterHotelType = _hotelData.hotel_type_list.filter((hotel_type_list) => {
          return hotelTypes.includes(hotel_type_list.hotel_type_code);
        });
        if (filterHotelType.length === 0) {
          return false;
        }
      }
      return _hotelData;
    }).filter((result) => result) as selectHotelDetail[];
 
    _hotelAllData.hotelList = hotelList;
  }
  return _hotelAllData as HotelAllSearchResponse;
}

/**
 * ホテル詳細検索用パラメータデータ取得
 * @param areaCode
 * @param hotelCode
 * @param query
 */
export const getHotelDetailSearchParms = (areaCode: string, hotelCode:string, query: PlanSearch) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  
  const params:searchHotelParams = {
    "searchType": "hotel",
    "params": {
      "area_code": areaCode,
      "hotel_code": hotelCode,
      "check_in": query?.checkin,
      "check_out": query?.checkout,
      "count_adults": peopleNum[countIndex].adult,
      "count_rooms": query.rooms,
    }
  };
    
  if (peopleNum[countIndex].children_a > 0) {
    params.params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.params.count_children_f = peopleNum[countIndex].children_f
  }
  
  return params;
}

/**
 * ホテル部屋詳細の検索用パラメータ取得
 * @param areaCode
 * @param hotelCode
 * @param query
 */
export const getHotelRoomSearchParms = (areaCode: string, hotelCode:string, query: PlanSearch) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  const stayDetail:stayDetailType[] = [];
  
  const params:searchHotelRoomParams = {
    "area_code": areaCode,
    "hotel_code": hotelCode,
    "check_in": query?.checkin,
    "check_out": query?.checkout,
    "count_adults": peopleNum[countIndex].adult,
    "count_rooms": query.rooms,
    "stay_detail": [],
  };

  if (peopleNum[countIndex].children_a > 0) {
    params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.count_children_f = peopleNum[countIndex].children_f
  }
  
  query.roomDetail.forEach((room) => {
    let adult = 0;
    let children_a = 0;
    let children_b = 0;
    let children_c = 0;
    let children_d = 0;
    let children_e = 0;
    let children_f = 0;
    
    adult = room.adult;
    room.child.forEach((child) => {
      const age = Number(child.age);
      if (age >= 10) {
        children_a++;
      } else if(age < 10 && age >= 6) {
        children_b++;
      } else if(age < 6){
        if (child.meal && child.futon) {
          children_c++
        } else if (child.meal) {
          children_d++
        } else if (child.futon) {
          children_e++;
        } else {
          children_f++
        }
      }
    });
    const stay_detailParam:stayDetailType  = {
      "count_adults": adult,
    }

    if (children_a > 0) {
      stay_detailParam.count_children_a = children_a
    }

    if (children_b > 0) {
      stay_detailParam.count_children_b = children_b
    }

    if (children_c > 0) {
      stay_detailParam.count_children_c = children_c
    }

    if (children_d > 0) {
      stay_detailParam.count_children_d = children_d
    }

    if (children_e > 0) {
      stay_detailParam.count_children_e = children_e
    }

    if (children_f > 0) {
      stay_detailParam.count_children_f = children_f
    }

    stayDetail.push(stay_detailParam);
  });
  
  // todo stay detail sort（最大人数の値を一番にもってくる）
  if (stayDetail.length > 0) {
    params.stay_detail = sortHotelStayDetail(stayDetail);
  }
  
  return params;
}

/**
 * ホテル詳細(部屋一覧)検索用パラメータ取得
 * @param areaCode
 * @param hotelCode
 * @param query
 */
export const getHotelRoomsSearchParms = (hotelCode:string, query: PlanSearch) => {
  const peopleNum =  getHotelPeopleNum(query);
  const countIndex = findMaxPeopleParamIndex(peopleNum);
  
  const params: searchHotelRoomsParams = {
    hotel_code: hotelCode,
    check_in: query?.checkin,
    check_out: query?.checkout,
    count_adults: peopleNum[countIndex].adult,
    count_rooms: query.rooms,
  };

  if (peopleNum[countIndex].children_a > 0) {
    params.count_children_a = peopleNum[countIndex].children_a
  }
    
  if (peopleNum[countIndex].children_b > 0) {
    params.count_children_b = peopleNum[countIndex].children_b
  }
    
  if (peopleNum[countIndex].children_c > 0) {
    params.count_children_c = peopleNum[countIndex].children_c
  }
    
  if (peopleNum[countIndex].children_d > 0) {
    params.count_children_d = peopleNum[countIndex].children_d
  }
    
  if (peopleNum[countIndex].children_e > 0) {
    params.count_children_e = peopleNum[countIndex].children_e
  }
  if (peopleNum[countIndex].children_f > 0) {
    params.count_children_f = peopleNum[countIndex].children_f
  }
  return params;
}

/**
 * ホテルの検索のパラメータ用データ取得
 * @param query
 */
export const getHotelPeopleNum = (query: PlanSearch) => {
  const stayParams: stayDetail[] = [];

  query.roomDetail.forEach((room) => {
    let adult = 0;
    let children_a = 0;
    let children_b = 0;
    let children_c = 0;
    let children_d = 0;
    let children_e = 0;
    let children_f = 0;
    adult += Number(room.adult);
    if (room.child.length > 0) {
      const childNums = childrenRoomNum(room.child);
      if (childNums.children_a > 0) {
        children_a += childNums.children_a;
      }
      if (childNums.children_b > 0) {
        children_b += childNums.children_b;
      }
      if (childNums.children_c) {
        children_c += childNums.children_c;
      }
      if (childNums.children_d) {
        children_d += childNums.children_d;
      }
      if (childNums.children_e) {
        children_e += childNums.children_e;
      }
      if (childNums.children_f) {
        children_f += childNums.children_f;
      }
    }
    stayParams.push({
      adult: adult,
      children_a: children_a,
      children_b: children_b,
      children_c: children_c,
      children_d: children_d,
      children_e: children_e,
      children_f: children_f,
    });
  });
  
  return stayParams;
}

/**
 * ホテル子供パラメータ取得
 * @param children
 */
export const childrenRoomNum = (children: roomDetailChild[]) => {
  let children_a = 0;
  let children_b = 0;
  let children_c = 0;
  let children_d = 0;
  let children_e = 0;
  let children_f = 0;

  children.forEach((child) => {
    const age = Number(child.age);
    if (age >= 10) {
      children_a++;
    } else if(age < 10 && age >= 6) {
      children_b++;
    } else if(age < 6){
      if (child.meal && child.futon) {
        children_c++
      } else if (child.meal) {
        children_d++
      } else if (child.futon) {
        children_e++;
      } else {
        children_f++
      }
    }
  })

  return {
    children_a: children_a,
    children_b: children_b,
    children_c: children_c,
    children_d: children_d,
    children_e: children_e,
    children_f: children_f,
  }
}

export const findMaxPeopleParamIndex = (stayDetail: stayDetail[]) => {
  let peopleMaxNum = 0;
  let findIndex = 0;
  
  stayDetail.forEach((detail, sIndex) => {
    let peopleNum = 0;
    peopleNum += detail.adult;
    peopleNum += detail.children_a;
    peopleNum += detail.children_b;
    peopleNum += detail.children_c;
    peopleNum += detail.children_e;
    if (peopleNum > peopleMaxNum) {
      peopleMaxNum = peopleNum;
      findIndex = sIndex;
    }
  })
  
  return findIndex;
}

/**
 * ホテル一覧時の料金差分
 * @param planSelectFormData
 * @param updateData
 */
export const diffHotelPrice = (planSelectFormData:PlanSelectFormData|undefined, updateData: updateHotel) => {
  if (planSelectFormData !== undefined && (updateData.updatePlan || updateData.updateHotel)) {
    const people = getHotelTotalPeople(planSelectFormData); // 旅行者の合計人数
    const totalPrice = planSelectFormData.basicPriceTotal / people;
    const updatePrice = getBasicTotalPrice(updateData, planSelectFormData) / people;
    return diffBasicPrice(totalPrice, updatePrice);
  }

  return diffBasicPrice(0, 0);
}

/**
 * ホテル追加・更新時の料金差分
 * @param planSelectFormData
 * @param updateData
 */
export const diffUpdateHotelPrice = (planSelectFormData:PlanSelectFormData|undefined, updateData: updateHotel) => {
    return diffHotelPrice(planSelectFormData, updateData);
}

/**
 * プラン一覧時の表示用にプランデータを分割
 * @param hotelData
 * @param roomData
 */
export const mergeHotelRoomPlans = (hotelData:hotelData|undefined, roomData: roomData[]) => {
  if (roomData === undefined) {
    return [];
  }
  return roomData?.map((room) => {
    
    if (room.plan_list[0]) {
      room.plan_list[0] = room.plan_list[0];
    }
    if (room.plan_list.length > 1) {
      room.plan_list = room.plan_list;
      room.plan_list.slice();
    }
    
    return room;
  })
  
}

/**
 * 部屋絞り込み
 * @param hotelListData
 * @param params
 */
export const filterRooms = (hotelData: HotelAllRoomSearchResponse | undefined, params: {
  roomTypes: string[],
  mealTypes: string[],
}) => {
  const roomTypes = params.roomTypes;
  const mealTypes = params.mealTypes;
  if (hotelData && (roomTypes.length > 0 || mealTypes.length > 0)) {
    const updateHotelData = _.cloneDeep(hotelData);
    let updateRoomList = updateHotelData.room_list;
    if (updateRoomList && updateRoomList.length > 0) {
      // `ルームタイプ`と`お食事`のAND検索
      const filterRoomList = updateRoomList.map((roomData) => {
        const filterRoomData = _.cloneDeep(roomData);

        // プランがない場合
        if (filterRoomData.plan_list.length === 0) {
          return false;
        }

        // 部屋を`ルームタイプ`で絞り込む(OR検索)
        if (roomTypes.length > 0) {
          let searchRoomTypes = _.cloneDeep(roomTypes);
          if (searchRoomTypes.includes("western_style")) {
            searchRoomTypes = [...searchRoomTypes, "1", "2", "3", "4", "5", "6"];
          }
          if (!searchRoomTypes.includes(filterRoomData.room_type_code)) {
            return false;
          }
        }

        // プランを`お食事`で絞り込む(OR検索)
        if (mealTypes.length > 0) {
          filterRoomData.plan_list = filterRoomData.plan_list.filter((plan) => {
            // 食事なし
            if (mealTypes.includes("meal_nothing") && plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
              return true;
            }
            // 朝食あり
            if (mealTypes.includes("meal_breakfast") && plan.meal_breakfast === "1") {
              return true;
            }
            // 昼食あり
            if (mealTypes.includes("meal_lunch") && plan.meal_lunch === "1") {
              return true;
            }
            // 夕食あり
            if (mealTypes.includes("meal_dinner") && plan.meal_dinner === "1") {
              return true;
            }
            return false;
          })
        }

        // 部屋の最初のプランと2つ目以降のプランを割り当て直す
        if (filterRoomData.plan_list[0]) {
          filterRoomData.plan_list[0] = filterRoomData.plan_list[0];
        }
        if (filterRoomData.plan_list.length > 1) {
          filterRoomData.plan_list = filterRoomData.plan_list;
          filterRoomData.plan_list.slice();
        }
        return filterRoomData;
      }).filter((result) => result);

      updateHotelData.room_list = filterRoomList as roomData[];
    }
    return updateHotelData;
  }
  return hotelData;
}

/**
 * ホテルの変更ができる日程日かチェック
 * @param date
 * @param checkIn
 */
export const hotelChangeDisabled = (date:string, checkIn: string) => {
  const diffDate = rageDiffDate(checkIn, date);
  return diffDate >= 1;
}

/**
 * ホテルのキャンセルができる日程日かチェック
 * @param date
 * @param checkIn
 * @param hotelList
 */
export const hotelCancelDisabled = (date: string, checkIn: string, hotelList: PlanSelectFromHotelType[]) => {
  const diffDate = rageDiffDate(checkIn, date);
  const hotelIndex = hotelList.findIndex((hotel) => {
    return hotel.checkIn === checkIn;
  });
  
  return !(hotelIndex === 0 && diffDate < 1);
}

export const selectHotelIndex = (hotelData: PlanSelectFromHotelType[], checkIn:string) => {
  return hotelData.findIndex((hotel) => {
    return hotel.checkIn === checkIn;
  })
}

/**
 * ホテル追加・更新時のホテル合計金額
 * @param planSelectData
 * @param isUpdate
 * @param updateHotelIndex
 * @param price
 */
export const updateHotelPrice = (planSelectData: PlanSelectFormData, isUpdate:boolean, updateHotelIndex:number|undefined, price:number) => {
  let hotelTotal = planSelectData.hotelTotal;
  
  if (isUpdate && updateHotelIndex !== undefined) {
    return planSelectData.hotel[updateHotelIndex].plan;
  }
  hotelTotal += price
  return hotelTotal;
}

export const updateHotelTotal = (planSelectData: PlanSelectFormData) => {
  let hotelTotal = 0;
  if (planSelectData.hotel.length > 0) {
    planSelectData.hotel.forEach((hotel) => {
      hotelTotal += hotel.plan?.room_plan_detail.total_price ?? 0;
    })
  }

  return hotelTotal;
}

export const getHotelGenderCode = (gender: string) => {
  if (gender === "male") {
    return "1";
  }
  
  return "2";
}

export const getHotelTotalPeople = (planSelectFormData: PlanSelectFormData) => {
  let people = 0;
  const peopleDetail = getHotelPeopleNum(planSelectFormData.searchParams);
  peopleDetail.forEach((detail) => {
    people += detail.adult;
    people += detail.children_a;
    people += detail.children_b;
    people += detail.children_c;
    people += detail.children_d;
    people += detail.children_e;
    people += detail.children_f;
  })
  return people;
}

export const showMealLabel = (meal_breakfast: string, meal_lunch: string, meal_dinner: string) => {
  let text = "";
  if (meal_breakfast === "0" && meal_lunch === "0" && meal_dinner === "0") {
    return "食事なし";
  }
  if (meal_breakfast === "1") {
    text += "朝";
  }
  if (meal_lunch === "1") {
    text += "昼";
  }
  if (meal_dinner === "1") {
    text += "夕";
  }
  
  return text + "食付き";
}

export const showRoomText = (hotelPlan: any) => {
  const room = {
    people: 0,
    room: 0,
  };
  let roomText = "";

  if (hotelPlan.room_plan_detail.stay_detail_list && hotelPlan.room_plan_detail.stay_detail_list.length > 0) {
    hotelPlan.room_plan_detail.stay_detail_list.forEach((stayDetail: any, index: number) => {
      room.room++;
      const peoples = {
        adult: 0,
        child: 0,
      };
      const countAdults = Number(stayDetail.count_adults) ?? 0;
      if (countAdults > 0) {
        peoples.adult = countAdults;
      }
      const countChildrenA = Number(stayDetail.count_children_a) ?? 0;
      if (countChildrenA > 0) {
        peoples.child += countChildrenA;
      }
      const countChildrenB = Number(stayDetail.count_children_b) ?? 0;
      if (countChildrenB > 0) {
        peoples.child += countChildrenB;
      }
      const countChildrenC = Number(stayDetail.count_children_c) ?? 0;
      if (countChildrenC > 0) {
        peoples.child += countChildrenC;
      }
      const countChildrenD = Number(stayDetail.count_children_d) ?? 0;
      if (countChildrenD > 0) {
        peoples.child += countChildrenD;
      }
      const countChildrenE = Number(stayDetail.count_children_e) ?? 0;
      if (countChildrenE > 0) {
        peoples.child += countChildrenE;
      }
      const countChildrenF = Number(stayDetail.count_children_f) ?? 0;
      if (countChildrenF > 0) {
        peoples.child += countChildrenF;
      }
      roomText += "客室" + toCircled(index+1);
      if (peoples.adult > 0) {
        roomText += "大人×"+ peoples.adult;
      }
      if (peoples.child > 0) {
        roomText += "　子供×"+ peoples.child;
      }
      roomText += "、";
    });
  }
  const roomCountText = room.room + "室";
  return roomCountText + "(" + roomText.slice( 0, -1) + ")";
};

export const sortHotelStayDetail = (stayDetail: stayDetailType[]) => {
  stayDetail.sort((first, second) => {
    let firstCount = 0;
    let secondCount = 0;
    firstCount += first.count_adults;
    firstCount += first.count_children_a ?? 0;
    firstCount += first.count_children_b ?? 0;
    firstCount += first.count_children_c ?? 0;
    firstCount += first.count_children_f ?? 0;

    secondCount += second.count_adults;
    secondCount += second.count_children_a ?? 0;
    secondCount += second.count_children_b ?? 0;
    secondCount += second.count_children_c ?? 0;
    secondCount += second.count_children_f ?? 0;

    if (firstCount < secondCount) {
      return  1
    } else if (firstCount > secondCount) {
      return -1;
    }

    return 0
  });
  
  return stayDetail;
}

/**
 * ホテル大エリア取得
 * @param planSelectFormData
 */
export const getHotelAreaCode = (planSelectFormData: PlanSelectFormData|undefined) => {
  if (planSelectFormData && planSelectFormData.sellerProduct && planSelectFormData.sellerProduct.hotelSettings.bigArea) {
    return planSelectFormData.sellerProduct.hotelSettings.bigArea
  } else if (planSelectFormData && planSelectFormData.planData?.sellerIndo.hotelSettings.bigArea) {
    return planSelectFormData.planData?.sellerIndo.hotelSettings.bigArea;
  }
  
  return 'okinawa';
}

/**
 * `宿泊の選択`の場所にスクロールする
 */
export const scrollToHotelSection = () => {
  const sectionHotel = document.getElementById("section-hotel");
  if (sectionHotel) {
      const top = sectionHotel.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      scroll.scrollTo(top + offset, { duration: 0 });
  }
}

/**
 * `宿泊の選択`の場所にスクロールする(モーダル)
 */
export const scrollToModalHotelSection = () => {
  const sectionHotel = document.querySelector('#modal-section-hotel');
  if (sectionHotel) {
    sectionHotel.scrollTop = 0;
  }
}

/**
 * ホテル部屋詳細の参加者人数詳細のデータが問題ないかチェック
 * @param stay_detail_list
 */
export const isHotelStaySubPrice = (stay_detail_list: stayDetailList[]) => {
  let checkSubTotal = true;
  if (stay_detail_list.length > 0) {
    stay_detail_list.forEach((detail) => {
      detail.subtotal_price_list.forEach((totalList) => {
        if (totalList.subtotal_price === 0) {
          checkSubTotal = false;
        }
      });
    });
  } else {
    checkSubTotal = false;
  }

  return checkSubTotal;
}

/**
 * プランを絞り込みして並び替える(モーダル用)
 * @param hotelDetail 
 * @param mealFilter 
 * @param roomFilter 
 * @param sortType 
 * @returns 
 */
export const filterAndSortHotelDetail = (
  hotelDetail: hotelList,
  mealFilter: string[],
  roomFilter: string[],
  sortType: string | undefined
) => {
  const resultHotelDetail: hotelList = _.cloneDeep(hotelDetail);
  if (resultHotelDetail.roomData?.length) {
    /**
     * `お部屋`と`お食事`のAND検索
     */
    let filterResultRoomData: any = resultHotelDetail.roomData.map((roomData: roomData) => {
      // プランがない場合
      if (roomData.plan_list.length === 0) {
        return false;
      }

      // `お部屋`で絞り込む(OR検索)
      if (roomFilter.length > 0) {
        let searchRoomTypes = _.cloneDeep(roomFilter);
        if (searchRoomTypes.includes("western_style")) {
          searchRoomTypes = [...searchRoomTypes, "1", "2", "3", "4", "5", "6"];
        }
        if (!searchRoomTypes.includes(roomData.room_type_code)) {
          return false;
        }
      }

      // `お食事`で絞り込む(OR検索)
      if (mealFilter.length > 0) {
        roomData.plan_list = roomData.plan_list.filter((plan) => {
          // 食事なし
          if (mealFilter.includes("meal_nothing") && plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
            return true;
          }
          // 朝食あり
          if (mealFilter.includes("meal_breakfast") && plan.meal_breakfast === "1") {
            return true;
          }
          // 昼食あり
          if (mealFilter.includes("meal_lunch") && plan.meal_lunch === "1") {
            return true;
          }
          // 夕食あり
          if (mealFilter.includes("meal_dinner") && plan.meal_dinner === "1") {
            return true;
          }
          return false;
        })
      }
      return roomData;
    }).filter((result) => result);

    /**
     * プランの並び替え
     */
    if (sortType && filterResultRoomData) {
      // 部屋ごとにプランを並び替え
      filterResultRoomData = filterResultRoomData.map((roomData: roomData) => {
        // 選択可能なプラン
        const enabled_plan = _.filter(roomData.plan_list, (roomDataPlan: roomDataPlanType) => {
          return (roomDataPlan.stay_detail_list) ? isHotelStaySubPrice(roomDataPlan.stay_detail_list) : false;
        });

        // 選択不可能なプラン
        const disabledPlan = _.filter(roomData.plan_list, (roomDataPlan: roomDataPlanType) => {
          return !_.find(enabled_plan, ["plan_code", roomDataPlan.plan_code]);
        });

        // 選択可能なプランを料金で並び替え
        roomData.plan_list = _.sortBy(enabled_plan, "total_price");
        if (sortType === "desc") {
          roomData.plan_list.reverse(); // 高い順
        }

        // 部屋のプランに選択不可能なプランを追加する
        if (disabledPlan) {
          roomData.plan_list.push(...disabledPlan);
        }

        return roomData;
      });

      // 部屋の最安値で並び替え
      filterResultRoomData = _.sortBy(filterResultRoomData, "min_price");
      if (sortType === "desc") {
        filterResultRoomData.reverse(); // 高い順
      }
    }
    resultHotelDetail.roomData = filterResultRoomData;
  }
  return resultHotelDetail;
}

/**
 * プランを絞り込みして並び替える(トップ用)
 * @param hotelData 
 * @param mealFilter 
 * @param roomFilter 
 * @param sortType 
 * @returns 
 */
export const filterAndSortRooms = (
  hotelData: HotelAllRoomSearchResponse,
  mealFilter: string[],
  roomFilter: string[],
  sortType: string | undefined
) => {
  const updateHotelData: HotelAllRoomSearchResponse = _.cloneDeep(hotelData);
  if (updateHotelData.room_list?.length) {
    /**
     * `お部屋`と`お食事`のAND検索
     */
    let filterResultRoomList: any = updateHotelData.room_list.map((roomData: roomData) => {
      // プランがない場合
      if (roomData.plan_list.length === 0) {
        return false;
      }

      // `お部屋`で絞り込む(OR検索)
      if (roomFilter.length > 0) {
        let searchRoomTypes = _.cloneDeep(roomFilter);
        if (searchRoomTypes.includes("western_style")) {
          searchRoomTypes = [...searchRoomTypes, "1", "2", "3", "4", "5", "6"];
        }
        if (!searchRoomTypes.includes(roomData.room_type_code)) {
          return false;
        }
      }
      
      // `お食事`で絞り込む(OR検索)
      if (mealFilter.length > 0) {
        roomData.plan_list = roomData.plan_list.filter((plan) => {
          // 食事なし
          if (mealFilter.includes("meal_nothing") && plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
            return true;
          }
          // 朝食あり
          if (mealFilter.includes("meal_breakfast") && plan.meal_breakfast === "1") {
            return true;
          }
          // 昼食あり
          if (mealFilter.includes("meal_lunch") && plan.meal_lunch === "1") {
            return true;
          }
          // 夕食あり
          if (mealFilter.includes("meal_dinner") && plan.meal_dinner === "1") {
            return true;
          }
          return false;
        })
      }
      return roomData;
    }).filter((result) => result);

    /**
     * プランの並び替え
     */
    if (sortType && filterResultRoomList) {
      // 部屋ごとにプランを並び替え
      filterResultRoomList = filterResultRoomList.map((roomData: roomData) => {
        // 選択可能なプラン
        const enabled_plan = _.filter(roomData.plan_list, (roomDataPlan: roomDataPlanType) => {
          return (roomDataPlan.stay_detail_list) ? isHotelStaySubPrice(roomDataPlan.stay_detail_list) : false;
        });

        // 選択不可能なプラン
        const disabledPlan = _.filter(roomData.plan_list, (roomDataPlan: roomDataPlanType) => {
          return !_.find(enabled_plan, ["plan_code", roomDataPlan.plan_code]);
        });

        // 選択可能なプランを料金で並び替え
        roomData.plan_list = _.sortBy(enabled_plan, "total_price");
        if (sortType === "desc") {
          roomData.plan_list.reverse(); // 高い順
        }

        // 部屋のプランに選択不可能なプランを追加する
        if (disabledPlan) {
          roomData.plan_list.push(...disabledPlan);
        }

        return roomData;
      });

      // 部屋の最安値で並び替え
      filterResultRoomList = _.sortBy(filterResultRoomList, "min_price");
      if (sortType === "desc") {
        filterResultRoomList.reverse(); // 高い順
      }
    }

    updateHotelData.room_list = filterResultRoomList as roomData[];
  }
  return updateHotelData;
}

export const isOneHotel = (planSelectFormData: PlanSelectFormData) => {
  if (planSelectFormData?.sellerProduct) {
    const target = planSelectFormData.sellerProduct.hotelSettings.target;
    const code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
    return target === "ホテル指定" && code.split(",").length === 1;
  }

  return false;
}

export const sortRecommendHotels = (hotelDetail: selectHotelDetail[], planSelectFormData: PlanSelectFormData) => {

  if (planSelectFormData?.sellerProduct) {
    const code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
    const codes = code.split(",");
    return hotelDetail.sort((hotelA, hotelB) => {
      return codes.indexOf(hotelA.hotel_code) - codes.indexOf(hotelB.hotel_code);
    })
  }

  return hotelDetail;
}