import React, {useEffect, Fragment, useState} from "react";
import _ from "lodash";

// for styles
import {
    Button,
    Backdrop
} from "@material-ui/core/";

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";

//モーダルをインポート
import Modal from "@material-ui/core/Modal";
import {
  HotelHandModal,
  PlanDetailModal,
  RoomDetailModal
} from "./"
import {defaultHotelDetail} from "../../defaults/hotel";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {HotelAllSearchResponse, hotelList, hotelSearchQuery, roomData, roomDataPlanType} from "../../types/Hotel";
import {
  filterAndSortHotelDetail, filterHotels, getHotel,
  getHotelAreaCode,
  hotelRoomSearch,
  hotelRoomsSearch,
  hotelsAllSearch,
  hotelsDetailSearch,
  hotelSearch,
  mergeHotelRoomPlans,
  scrollToModalHotelSection,
  selectHotelIndex, sortRecommendHotels
} from "../../utils/hotel";
import {dateFormat, dayAgo, nextDate, rageDiffDate, rangeDate, toCircled} from "../../utils/convert";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {okinawaAreaCodeLabel, roomTypeCode} from "../../config/hotel";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {devLog} from "../../utils/errors";
import StaySelectModalStep1 from "./PlanSelectConfirm/StaySeletModalStep1";
import StaySelectModalStep2 from "./PlanSelectConfirm/StaySeletModalStep2";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  isShow: boolean,
  isUpdate: boolean,
  updateHotelIndex: number|undefined,
  callback: () => void,
  defaultStep: number,
  selectHotelCode: string,
  date: string,
  planSelectFormData: PlanSelectFormData,
  stepHandler: ( step: number ) => void,
  updateSelectHotel(planSelectFormData: PlanSelectFormData): void,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const MyStepModal: React.FC<Props> = ({
  isShow,
  isUpdate,
  updateHotelIndex,
  callback,
  selectHotelCode,
  defaultStep,
  date,
  planSelectFormData,
  stepHandler,
  updateSelectHotel
}) => {
  const target = planSelectFormData.sellerProduct ? planSelectFormData.sellerProduct.hotelSettings.target : planSelectFormData.planData?.sellerIndo.hotelSettings.target;

  // 宿泊施設(ホテル)
  const [hotelController, setHotelController] = useState(new AbortController())
  const hotelRoomAllAbortController = new AbortController();
  const hotelPlanAbortController = new AbortController();
  const [hotelAllData, setHotelAllData] = React.useState<HotelAllSearchResponse>({
    total: 0,
    currentPage: 0,
    totalPage: 0,
    search_sort: 0,
    status: 0,
    success: true,
    error: undefined,
    hotelList: [],
    subAreaCodes: '',
    hotelTypeCodes: '',
  }); // ホテル一覧で取得したデータ

  const classesForLoading = useStyles();
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isListLoading, setIsListLoading] = React.useState(false);
  const [hotelCode, setHotelCode] = React.useState<string>(selectHotelCode);
  const [hotelList, setHotelList] = React.useState<hotelList>(defaultHotelDetail);
  const [hotelDetail, setHotelDetail] = React.useState<hotelList>();
  const [isSelectedRooms, setIsSelectedRooms] = React.useState(false);
  const [defaultHotelDetailData, setDefaultHotelDetailData] = React.useState<hotelList>();
  const [roomData, setRoomData] = React.useState<roomData|undefined>(undefined);
  const [planData, setPlanData] = React.useState<roomDataPlanType|undefined>(undefined);
  const [checkIn, setCheckIn] = React.useState<string>("");
  const [checkOut, setCheckOut] = React.useState<string>("");
  const [isDateError, setIsDateError] = React.useState(false);
  const searchParams = planSelectFormData.searchParams;
  const minDate = planSelectFormData.searchParams.start;
  const maxDate = planSelectFormData.searchParams.end;
  const [planExists, setPlanExists] = React.useState(false); // プランが1つでも存在するか

  const [hotelData, setHotelData] = useState<HotelAllSearchResponse | undefined>();

  // ホテルの絞り込み条件
  const [hotelAreas, setHotelAreas] = useState<string[]>([]);
  const [hotelTypes, setHotelTypes] = useState<string[]>([]);
  const [hotelAreasFilterState, setHotelAreasFilterState] = React.useState<string[]>([]);
  const [hotelTypesFilterState, setHotelTypesFilterState] = React.useState<string[]>([]);
  const [areaSelectOption, setAreaSelectOption] = React.useState<string[]>([]);
  const [defaultHotels, setDefaultHotels] = React.useState<hotelList[]>([]);

  const getHotelAreas = (searchType: string | undefined) => {
    if (searchType === 'エリア指定') {
      return planSelectFormData.sellerProduct ? planSelectFormData.sellerProduct.hotelSettings.hotelCode : planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode;
    }
    return hotelAllData?.subAreaCodes;
  }
  const hotelSearchType = planSelectFormData.sellerProduct ? planSelectFormData.sellerProduct.hotelSettings.target : planSelectFormData.planData?.sellerIndo.hotelSettings.target;
  const areas = getHotelAreas(hotelSearchType);
  if (areas) {
    const areaCodes = areas.split(",");
    if (areaCodes.length > 1) {
      areaCodes.forEach((areaCode, index) => {
        if (!hotelAreas.includes(areaCode)) {
          setHotelAreas([...hotelAreas, areaCode])
        }
      })
    }
  }
  if (hotelAllData?.hotelTypeCodes) {
    const typeCodes = hotelAllData.hotelTypeCodes.split(",");
    if (typeCodes.length > 1) {
      typeCodes.forEach((typeCode, index) => {
        if (!hotelTypes.includes(typeCode)) {
          setHotelTypes([...hotelTypes, typeCode])
        }
      })
    }
  }

  const handleHotelTypesFilter = (
    event: React.MouseEvent<HTMLElement>,
    newHotelTypesToggle: string[],
  ) => {
    setHotelTypesFilterState(newHotelTypesToggle);
  };

  const handleHotelAreasFilter = (
    event: React.MouseEvent<HTMLElement>,
    newHotelAreasToggle: string[],
  ) => {
    setHotelAreasFilterState(newHotelAreasToggle);
  };

  // todo ホテルAPI修正できれば不要になる
  const setHotelAreasByHotelAllData = () => {
    if (hotelAllData) {
      const _hotelAreas = _.cloneDeep(hotelAreas);
      hotelAllData.hotelList.forEach((hotelData) => {
        if (!_hotelAreas.includes(hotelData.sub_area_code)) {
          _hotelAreas.push(hotelData.sub_area_code)
        }
      })
      const areaSortArray = Object.keys(okinawaAreaCodeLabel);
      setHotelAreas(_hotelAreas.sort((a, b) => {
        return areaSortArray.indexOf(a) - areaSortArray.indexOf(b);
      }));
    }
  }

  // todo ホテルAPI修正できれば不要になる
  const setHotelTypesByHotelAllData = () => {
    const _hotelTypes = _.cloneDeep(hotelTypes);
    if (hotelAllData) {
      hotelAllData.hotelList.forEach((hotelData) => {
        if (hotelData.hotel_type_list && hotelData.hotel_type_list.length > 0) {
          hotelData.hotel_type_list.forEach((hotelTypeData) => {
            if (!_hotelTypes.includes(hotelTypeData.hotel_type_code)) {
              _hotelTypes.push(hotelTypeData.hotel_type_code)
            }
          });
        }
      })
      setHotelTypes(_hotelTypes.sort());
    }
  }

  useEffect(() => {
    setHotelAreasByHotelAllData();
    setHotelTypesByHotelAllData();
    if (hotelAllData && hotelAllData.hotelList.length > 0) {
      const updateHotelData = filterHotels(hotelAllData, hotelAreasFilterState, hotelTypesFilterState);
      setHotelData(updateHotelData);
    }
  }, [hotelAreasFilterState, hotelTypesFilterState, hotelAllData]);

  useEffect(() => {
    if (hotelData && hotelData?.hotelList.length > 0) {
      setLoading(false);
    }
  }, [hotelData]);


  // ホテルの並び替え条件
  const [selectedHotelSort, setSelectedHotelSort] = React.useState<number>(0); // 0: おすすめ順, 1: 価格が安い順, 2: 価格が高い順
  // 宿泊プランの並び替え条件
  const [selectedRoomSort, setSelectedRoomSort] = React.useState("");
  const [actionInitHotel, setActionInitHotel] = useState(false);
  const [updateHotelSearchParams, setHotelSearchParams] = useState({
    areaCode: '',
    target: '',
    code: '',
    sort: 1,
  })

  // 絞り込み条件(ルームタイプ)
  const [roomTypeFilter, setRoomTypeFilter] = React.useState<{label: string, value: string}[]>([]);
  const [mealFilter, setMealFilter] = React.useState<{label: string, value: string}[]>([]);

  const [roomTypeFilterState, setRoomTypeFilterState] = React.useState<string[]>([]);
  /**
   * お部屋タイプで絞り込み
   * @param event
   * @param newRoomDetailToggle
   */
  const handleRoomTypeFilter = (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],
  ) => {
    setRoomTypeFilterState(newRoomDetailToggle);
  };

  const [mealFilterState, setMealFilterState] = React.useState<string[]>([]);
  /**
   * お食事で絞り込み
   * @param event
   * @param newRoomDetailToggle
   */
  const handleMealFilter = (
    event: React.MouseEvent<HTMLElement>,
    newRoomDetailToggle: string[],
  ) => {
    setMealFilterState(newRoomDetailToggle);
  };

  /**
   * 宿泊施設の並び替え
   * @param sortKey
   * @param sortType
   */
  const sortHotels = async (sortType: number) => {
    setHotelData(undefined);
    setLoading(true);
    setSelectedHotelSort(sortType);
    let code = planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode ?? "";

    // 基本プランに商品が設定されている場合、商品のデータを使用する
    if (planSelectFormData.planData?.product?.hotelSettings) {
      code = planSelectFormData.planData.product.hotelSettings.hotelCode;
    }
    const areaCode = getHotelAreaCode(planSelectFormData);

    setActionInitHotel(true);
    setHotelSearchParams((prevState) => ({
      ...prevState,
      areaCode: areaCode,
      code: code,
      sort: sortType
    }))
    setHotelAllData((prevState) => ({
      ...prevState,
      hotelList: []
    }))
    hotelController.abort();
  }

  /**
   * 宿泊プランのソート
   * @param sortType
   */
  const sortRoomPlan = (sortType: string) => {
    setSelectedRoomSort(sortType);
  }

  /**
   * モーダル表示・非表示時
   */
  useEffect(() => {
    if (defaultStep === 1) {
      // ホテルの絞り込み条件を設定
      setSelectedHotelSort(0);
    } else if (defaultStep === 2) {
      // 宿泊プランと並び替えの条件を初期化
      setMealFilter([]);
      setRoomTypeFilter([]);
      setRoomTypeFilterState([]); // 部屋タイプ
      setMealFilterState([]); // 食事
    }

    if (!isShow) {
      setIsDateError(false);
      setCheckIn("");
      setCheckOut("");
    }

    actionHotelSearch(undefined, undefined);
  },[isShow, hotelCode])

  /**
   * ステップ切替時
   */
  useEffect(() => {
    if (defaultStep === 1 && hotelDetail) {
      actionHotelSearch(checkIn, checkOut);
    } else if(defaultStep === 2 && hotelCode) {
      actionHotelSearch(checkIn, checkOut);
    }
  }, [defaultStep])

  /**
   * チェックイン・チェックアウトを設定
   * @param checkInDate
   * @param checkOutDate
   * @returns
   */
  const setDate = (checkInDate: string | undefined, checkOutDate: string | undefined) => {
    const checkDate = {
      checkIn: "",
      checkOut: ""
    }

    if ( checkInDate !== undefined && checkInDate !== "" && checkOutDate !== undefined && checkOutDate !== ""){
      checkDate.checkIn = checkInDate;
      checkDate.checkOut = checkOutDate;
      setCheckIn(checkInDate);
      setCheckOut(checkOutDate);
    } else if (isUpdate)  {
      const updateHotelIndex = selectHotelIndex(planSelectFormData.hotel, date);
      const updateHotel = planSelectFormData.hotel[updateHotelIndex];
      const updateCheckIn = updateHotel?.checkIn ?? date;
      const updateCheckOut = updateHotel?.checkOut ?? nextDate(date, 1, 'YYYY-MM-DD');

      checkDate.checkIn = updateCheckIn;
      checkDate.checkOut = updateCheckOut;
      setCheckIn(updateCheckIn);
      setCheckOut(updateCheckOut);
    } else {
      const updateCheckIn = date;
      const updateCheckOut = nextDate(date, 1, 'YYYY-MM-DD');
      checkDate.checkIn = updateCheckIn;
      checkDate.checkOut = updateCheckOut;
      setCheckIn(updateCheckIn);
      setCheckOut(updateCheckOut);
    }

    return checkDate;
  }

  /**
   * 絞り込み条件と並び替え用のインデックス追加
   * @param hotelList
   */
  const updateFilterOptions = (hotelList: hotelList[]) => {
    let newHotelList = _.cloneDeep(hotelList);

    let code = planSelectFormData?.planData?.sellerIndo?.hotelSettings?.hotelCode ?? "";

    // 基本プランに商品が設定されている場合、商品のデータを使用する
    if (planSelectFormData.planData?.product?.hotelSettings) {
      code = planSelectFormData.planData.product.hotelSettings.hotelCode;
    }

    if (target === "エリア指定") {
      setAreaSelectOption(["all", ...code.split(",")]); // エリアの絞り込みの項目を更新
    }
    setDefaultHotels([...newHotelList]);
  }

  const abortController = new AbortController();
  /**
   * ホテル検索
   * @param checkInDate
   * @param checkOutDate
   */
  const actionHotelSearch = (checkInDate: string | undefined, checkOutDate: string | undefined) => {
    setLoading(true)
    const checkDate = setDate(checkInDate, checkOutDate);
    const updateCheckIn = checkDate.checkIn;
    const updateCheckOut = checkDate.checkOut;
    const hotelAreaCode = getHotelAreaCode(planSelectFormData);
    if (updateCheckIn !== "" && updateCheckOut !== "" && isShow) {
      // ホテル、プラン変更
      (async () => {
        const params = planSelectFormData.searchParams;
        params.checkin = updateCheckIn;
        params.checkout = updateCheckOut;
        if (defaultStep === 1) {
          let target = planSelectFormData.planData?.sellerIndo.hotelSettings.target ?? "";
          let code = planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode ?? "";

          // 基本プランに商品が設定されている場合、商品のデータを使用する
          if (planSelectFormData.planData?.product?.hotelSettings) {
            target = planSelectFormData.planData.product.hotelSettings.target;
            code = planSelectFormData.planData.product.hotelSettings.hotelCode;
          }

          setHotelSearchParams((prevState) => ({
            ...prevState,
            areaCode: areaCode,
            code: code,
            sort: target === "ホテル指定" ? 0 : 1
          }))
          // ホテル一覧取得
          const areaCode = getHotelAreaCode(planSelectFormData);
          const searchQuery: hotelSearchQuery = {
            area_code: areaCode,
            target: target,
            code: code,
            query: searchParams,
            paged: 1,
            search_sort: 0
          }
          const resultHotels: HotelAllSearchResponse = await hotelsAllSearch(hotelController, searchQuery);
          if (resultHotels.success) {
            setHotelAllData({...resultHotels});
            updateFilterOptions(resultHotels.hotelList); // 絞り込み条件と並び替え用のインデックス追加
            if (target === "ホテル指定") {
              setSelectedHotelSort(0);
            } else {
              setSelectedHotelSort(1);
            }
          }

        } else if (defaultStep === 2 && hotelCode !== "") {
          const params = planSelectFormData.searchParams;
          params.checkin = updateCheckIn;
          params.checkout = updateCheckOut;
          const searchQuery: hotelSearchQuery = {
            area_code: hotelAreaCode,
            target: "ホテル指定",
            code: hotelCode,
            query: searchParams
          }
          const resultHotel = await hotelSearch(hotelController, searchQuery, 1);
          const hotelSearchFilter = resultHotel.filter((item) => item.hotel_code === hotelCode)[0];
          if (hotelSearchFilter !== undefined) {
            const cHotel = hotelSearchFilter;

            if(cHotel !== undefined && cHotel.hotelData === undefined && planSelectFormData !== undefined) {
              const resultHotelDetail = await hotelsDetailSearch(hotelAreaCode, searchParams, hotelCode);
              if (resultHotelDetail) {
                cHotel.hotelData = resultHotelDetail;
              }
            }

            if(cHotel !== undefined && cHotel.roomData === undefined && planSelectFormData !== undefined) {
              const resultHotelRooms = await hotelRoomsSearch(searchParams, cHotel.hotel_code);
              if(resultHotelRooms) {
                cHotel.roomData = mergeHotelRoomPlans(cHotel.hotelData, resultHotelRooms.room_list);
              }
            }

            const planIsExistsRooms = _.filter(cHotel?.roomData, (room) => room.plan_list.length > 0)
            if (cHotel !== undefined && planIsExistsRooms.length > 0) {
              setPlanExists(true);
              let roomTypeList: {label: string, value: string}[] = []; // 選択可能なルームタイプ
              let mealList: {label: string, value: string}[] = []; // 選択可能な食事
              const accordionStateList: boolean[] = [];
              if (cHotel?.hotelData?.room_list) {
                cHotel.hotelData.room_list.forEach((roomData) => {
                  const roomType = roomTypeCode[roomData.room_type_code];
                  roomTypeList.push({label: roomType, value: roomData.room_type_code});

                  // 食事の有無("1"=あり、"0"=なし)
                  roomData.plan_list.forEach((plan: any) => {
                    if (plan.meal_breakfast === "1") {
                      mealList.push({label: "朝食付き", value: "meal_breakfast"});
                    }
                    if (plan.meal_lunch === "1") {
                      mealList.push({label: "昼食付き", value: "meal_lunch"});
                    }
                    if (plan.meal_dinner === "1") {
                      mealList.push({label: "夕食付き", value: "meal_dinner"});
                    }
                    if (plan.meal_breakfast === "0" && plan.meal_lunch === "0" && plan.meal_dinner === "0") {
                      mealList.push({label: "食事なし", value: "meal_nothing"});
                    }
                  })
                  accordionStateList.push(false);
                  return roomData;
                })

              }
              roomTypeList = _.uniqBy(roomTypeList, "label");
              mealList = _.uniqBy(mealList, "label");
              setMealFilter(mealList);
              setRoomTypeFilter(roomTypeList);
              setHotelDetail({...cHotel});
              setDefaultHotelDetailData({...cHotel});
              SetStayAccordionStateList(accordionStateList); // アコーディオンの状態
              setIsSelectedRooms(true);
            } else {
              setPlanExists(false);
              setMealFilter([]); // 絞り込み条件(食事)
              setRoomTypeFilter([]); // 絞り込み条件(部屋タイプ)
              SetStayAccordionStateList([]); // アコーディオンの状態
            }
          }
        }

      } )()

    }
  }

  useEffect(() => {

    if (actionInitHotel) {
      if (hotelAllData.hotelList.length > 0) {
        setHotelAllData((prevState) => ({
          ...prevState,
          hotelList: [],
        }));
      } else {
        (async () => {
          // ホテル一覧取得
          const searchQuery: hotelSearchQuery = {
            area_code: updateHotelSearchParams.areaCode,
            target: target ?? 'エリア指定',
            code: updateHotelSearchParams.code,
            query: searchParams,
            paged: 1,
            search_sort: updateHotelSearchParams.sort
          }
          const abortController = new AbortController()
          const resultHotels: HotelAllSearchResponse = await hotelsAllSearch(abortController, searchQuery);
          if (resultHotels.success) {
            setHotelAllData({...resultHotels});
            updateFilterOptions(resultHotels.hotelList); // 絞り込み条件と並び替え用のインデックス追加
          }
          setActionInitHotel(false);
        })()
      }
    } else if (hotelAllData && hotelAllData.currentPage !== hotelAllData.totalPage) {
      addHotelList();
    } else {
      setIsListLoading(false);
    }
  }, [hotelAllData])

  const addHotelList = () => {
    (async () => {
      if (hotelAllData.totalPage > 1) {
        setIsListLoading(true)

        setHotelController(abortController);
        const paged = hotelAllData.currentPage + 1;
        const areaCode = getHotelAreaCode(planSelectFormData);
        let target = planSelectFormData.planData?.sellerIndo.hotelSettings.target ?? "";
        let code = planSelectFormData.planData?.sellerIndo.hotelSettings.hotelCode ?? "";

        // 基本プランに商品が設定されている場合、商品のデータを使用する
        if (planSelectFormData.planData?.product?.hotelSettings) {
          target = planSelectFormData.planData.product.hotelSettings.target;
          code = planSelectFormData.planData.product.hotelSettings.hotelCode;
        }

        const searchQuery: hotelSearchQuery = {
          area_code: areaCode,
          target: target,
          code: code,
          query: searchParams,
          paged: paged,
          search_sort: selectedHotelSort
        }
        const resultHotels: HotelAllSearchResponse = await hotelsAllSearch(abortController, searchQuery);
        if (resultHotels.success && hotelAllData) {
          const updateHotelList = [...hotelAllData?.hotelList].concat(resultHotels?.hotelList);
          updateFilterOptions(updateHotelList); // 絞り込み条件と並び替え用のインデックス追加
          setHotelAllData((prevState) => ({
            ...prevState,
            hotelList: updateHotelList,
            currentPage: resultHotels.currentPage
          }))

          if (resultHotels.currentPage === resultHotels.totalPage) {
            setIsListLoading(false);
          }
        }
      }
    })();
  }

  /**
   * チェックイン日変更
   * @param newValue
   */
  const changeCheckIn = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (checkOut && rageDiffDate(_date, checkOut) <= 0) {
      alert("チェックアウト日より前の日を選択してください");
    } else if (checkDateRange(_date, "in", updateHotelIndex)) {
      setIsDateError(true);
      alert("指定したチェックイン日は選択できません。");
    } else {
      setIsDateError(false);
      actionHotelSearch(_date, checkOut);
    }
  }

  /**
   * チェックアウト日変更
   * @param newValue
   */
  const changeCheckOut = (newValue: Date | null) => {
    const _date = dayjs(newValue).format('YYYY-MM-DD');
    if (checkIn && rageDiffDate(checkIn, _date) <= 0) {
      alert("チェックイン日より前の日を選択してください");
    } else if (checkDateRange(_date, "out", updateHotelIndex)) {
      setIsDateError(true);
      alert("指定したチェックアウト日は選択できません。");
    } else {
      setIsDateError(false);
      actionHotelSearch(checkIn, _date);
    }
  }

  const checkDateRange = (date: string, checkType: string, updateIndex: number | undefined) => {
    let dateRange:string[] = [];
    let setDateRange:string[] = [];
    planSelectFormData.hotel.forEach((hotel, index) => {
      let range: string[] = [];
      const checkInDate = hotel.checkIn;
      const checkOutDate = hotel.checkOut;
      if (updateIndex === undefined || updateIndex !== index) {
        if(checkType === "out") {
          range = rangeDate(nextDate(checkInDate, 1, "YYYY-MM-DD"), checkOutDate);
        } else if(checkType === "in") {
          range = rangeDate(checkInDate, dayAgo(checkOutDate, 1, "YYYY-MM-DD"));
        }
        if (range.length > 0) {
          dateRange = dateRange.concat(range);
        }
      }
    })

    if (dateRange.length > 0) {
      if (checkType === 'in') {
        setDateRange = rangeDate(date, dayAgo(checkOut, 1, "YYYY-MM-DD"));
      } else if (checkType === 'out'){
        setDateRange = rangeDate(nextDate(checkIn, 1, "YYYY-MM-DD"), date);
      }
      if (setDateRange.length > 0) {
        devLog("setDateRange", setDateRange);
        devLog("dateRange", dateRange);
        return setDateRange.filter((setDate) => dateRange.includes(setDate) ).length > 0;
      }
    }

    return false;
  }

  // // モーダルのステータスを追加する
  const [hotelHandModalState, setHotelHandModalState] = React.useState(false);

  // モーダルのhandleを追加する
  const handleHotelDetail = (hotelData: hotelList) => {
    setHotelList({...hotelData})
    setHotelHandModalState(!hotelHandModalState)
  }
  const handleHotelHandModal = () => {
    setHotelHandModalState(!hotelHandModalState)
  }

  const onPageHandle = ( step:number ) => {
    stepHandler(step);
    setIsSelectedRooms(false)
    scrollToModalHotelSection(); // モーダルのトップへスクロールする
  }

  /**
   * 宿泊施設を選択
   * @param code
   */
  const onPageHandlePlanDetail = (code: string) => {
    if (code) {
      // 選択済みの絞り込み条件を選択解除する
      setRoomTypeFilterState([]); // 部屋タイプ
      setMealFilterState([]); // 食事
      setMealFilter([]);
      setRoomTypeFilter([]);
      setSelectedRoomSort(""); // 並び順
      setHotelCode(code); // ホテルコードを設定
      stepHandler(2);
    }
  }

  /**
   * ホテル選択
   * @param code
   */
  const onPageModalHandlePlanDetail = ( code:string ) => {
    if (code) {
      setHotelCode(code)
      setHotelHandModalState(!hotelHandModalState)
      stepHandler(2)
    }
  }


  //アコーディオン：制御
  const[StayAccordionStateList, SetStayAccordionStateList] = React.useState<boolean[]>([]);
  const handleStayAccordionList = (index: number) => {
    if (StayAccordionStateList[index] !== undefined) {
      const updateStatusList = _.cloneDeep(StayAccordionStateList);
      updateStatusList[index] = !updateStatusList[index];
      SetStayAccordionStateList(updateStatusList);
    }
  }

  /**
   * プラン選択
   * @param planData
   * @param room
   */
  const callbackUpdateSelectHotel = (planData: roomDataPlanType|undefined, room:roomData) => {
    if (planSelectFormData !== undefined && hotelDetail !== undefined) {
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);
      (async () => {
        setUpdateLoading(true);
        if (isUpdate && updateHotelIndex !== undefined && planSelectFormData.hotel[updateHotelIndex] !== undefined) {
          // ルーム詳細取得
          const resultRoom = await hotelRoomSearch(hotelPlanAbortController, hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.room_code, planData?.plan_code);
          if (resultRoom && resultRoom.success) {
            planSelectFormData.hotel[updateHotelIndex].detail = hotelDetail;
            planSelectFormData.hotel[updateHotelIndex].plan = resultRoom.response;
            planSelectFormData.hotel[updateHotelIndex].roomData = room;
            planSelectFormData.hotel[updateHotelIndex].checkIn = checkIn;
            planSelectFormData.hotel[updateHotelIndex].checkOut = checkOut;
            planSelectFormData.hotel[updateHotelIndex].useDate = checkIn;
            planSelectFormData.hotel[updateHotelIndex].area = hotelDetail.sub_area_code?? "";
            planSelectFormData.hotel[updateHotelIndex].checkTime = hotelDetail.hotelData?.hotel_info.check_in_start;
            planSelectFormData.hotel[updateHotelIndex].total = planData?.total_price?? 0;
            planSelectFormData.hotel[updateHotelIndex].planCode = planData?.plan_code;
            planSelectFormData.hotel[updateHotelIndex].planRoomCode = resultRoom.response.room_plan_detail.room_plan_code;
          } else {
            alert("指定したホテルが選択できませんでした。");
          }
        } else {
          // ルーム詳細取得
          const resultRoom = await hotelRoomSearch(hotelPlanAbortController, hotelAreaCode, planSelectFormData.searchParams, hotelCode, room.room_code, planData?.plan_code);
          if (resultRoom && resultRoom.success) {
            planSelectFormData.hotel.push({
              detail: hotelDetail,
              plan: resultRoom.response,
              roomData: room,
              checkIn: checkIn,
              checkOut: checkOut,
              useDate: checkIn,
              area: hotelDetail.sub_area_code ?? "",
              checkTime: hotelDetail.hotelData?.hotel_info.check_in_start,
              total: planData?.total_price ?? 0,
              planRoomCode: resultRoom.response.room_plan_detail.room_plan_code
            });
          } else {
            alert("指定したホテルが選択できませんでした。");
          }
        }
        setUpdateLoading(false);
        updateSelectHotel({...planSelectFormData});
        callback();
        if (PlanDetailModalState) {
          setPlanDetailModalState(!PlanDetailModalState);
        }
      })();
    }
  }

  /**
   * 選択したホテルコードを設定
   */
  useEffect(() => {
    setHotelCode(selectHotelCode);
  }, [selectHotelCode])

  //モーダル：プラン詳細詳細
  const [PlanDetailModalState, setPlanDetailModalState] = React.useState(false);

  // モーダル：客室詳細
  const [roomDetailModalState, setRoomDetailModalState] = React.useState(false);
  const handleRoomDetailModal = () => {
    setRoomDetailModalState(!roomDetailModalState);
  };

  /**
   * 客室詳細を見る
   * @param roomData
   */
  const openRoomDetailModal = (roomData: roomData) => {
    setRoomData({...roomData});
    setRoomDetailModalState(!roomDetailModalState);
  }

  /**
   * プラン詳細を見る
   * @param planDetail
   * @param roomData
   */
  const onClickPlandetailModal = (planDetail: roomDataPlanType|undefined, roomData: roomData) => {
    setRoomData({...roomData});
    if (planDetail !== undefined) {
      setPlanData({...planDetail})
    }
    setPlanDetailModalState(!PlanDetailModalState);
  };

  const handlPlandetailModal = () => {
    setPlanDetailModalState(!PlanDetailModalState);
  };

  const roomBaxClass = (index:number) => {
    if (index > 0) {
      return "box-white radius-none small-pc mt-40";
    }
    return "box-white radius-none small-pc "
  };

  /**
   * プラン絞り込みと並び替え
   */
  useEffect(() => {
    if (defaultHotelDetailData) {
      const resultHotelDetail = filterAndSortHotelDetail(defaultHotelDetailData, mealFilterState, roomTypeFilterState, selectedRoomSort);
      setHotelDetail({...resultHotelDetail});
    }
  }, [roomTypeFilterState, mealFilterState, selectedRoomSort]);

  const updateHotelDetail = (updateHotelData: hotelList) => {
    setHotelDetail({...updateHotelData});
  }

  //readOnly属性を付与するとdate-pickerが表示されなくなるので対策
  const ForceReadOnlyInput = React.forwardRef((props, ref) => (
    <input {...props} readOnly={true} />
  ));

  const closeHotelModal = () => {
    hotelController.abort();
    hotelRoomAllAbortController.abort();
    hotelPlanAbortController.abort()
    callback();
  }

  return (
    <>
      <Modal
        open={isShow}
        onClose={closeHotelModal}
        className="modal"
        BackdropComponent={Backdrop}
        BackdropProps={{timeout: 300}}
      >
        <div>
          <Backdrop className={classesForLoading.backdrop} open={updateLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="modal-fixed bgc-light_cyan" id="modal-section-hotel">
            <div className="modal-fixed-heading">
              <p className="modal-fixed-heading-text">宿泊の選択</p>
              <p className="modal-fixed-close">
                <Button onClick={callback} className="modal-fixed-close-button">
                  <i className="modal-fixed-close-button-icon"></i>閉じる
                </Button>
              </p>
            </div>
            <div className="modal-fixed-content pb-small">
              <div className="modal-fixed-content-inner small">
                <h2 className="heading-2 mt-0">
                  {defaultStep === 1 ? "ご希望の宿泊施設をお選びください" : "ご希望の宿泊プランをお選びください"}
                </h2>
                <div>
                  {/* 宿泊条件 */}
                  <div className="card medium mb-20">
                    <div className="serchbox-stay">
                      <p className="serchbox-stay-item fz-16 fz-15-md mb-16 mb-0-md fw-b ta-c pr-20-md w-180-md">宿泊条件</p>
                      <div className="d-f-md fw-w bl-gray-md pl-30-md">
                        <div className="w-100per">
                          {/* チェックイン・チェックアウト */}
                          <div className="searchbox-content-item-group-block">
                            <div className="searchbox-content-item-group-block-child small">
                              <div className="searchbox-content-item-group-block-child-item fx-1">
                                <p className="mb-4 fz-12">チェックイン</p>
                                <div className="w-165-md">
                                  <div className="form-date-seal">
                                    <div className="form-input-date-icon-wrap">
                                      <DatePicker
                                        dateFormat="yyyy/MM/dd"
                                        locale='ja'
                                        selected={new Date(checkIn)}
                                        value={dateFormat(checkIn, 'YYYY/MM/DD')}
                                        className="form-input-date"
                                        onChange={changeCheckIn}
                                        minDate={new Date(minDate)}
                                        maxDate={new Date(maxDate)}
                                        customInput={<ForceReadOnlyInput />}
                                        disabled={loading}
                                      />
                                      <p className="form-date-seal-label"> {dateFormat(checkIn, "YYYY/MM/DD")}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="searchbox-content-item-group-block-child-item fx-1">
                                <p className="mb-4 fz-12">チェックアウト</p>
                                <div className="w-165-md">
                                  <div className="form-date-seal">
                                    <div className="form-input-date-icon-wrap">
                                      <DatePicker
                                        dateFormat="yyyy/MM/dd"
                                        locale='ja'
                                        selected={new Date(checkOut)}
                                        value={dateFormat(checkOut, 'YYYY/MM/DD')}
                                        className="form-input-date"
                                        onChange={changeCheckOut}
                                        minDate={new Date(minDate)}
                                        maxDate={new Date(maxDate)}
                                        customInput={<ForceReadOnlyInput />}
                                        disabled={loading}
                                      />
                                      <p className="form-date-seal-label"> {dateFormat(checkOut, "YYYY/MM/DD")}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isDateError && (
                      <div className="box-alert-red-medium icon-alert">
                        <p className="c-red fw-b mb-8">設定済みの宿泊日程が含まれているため選択できません。</p>
                        <p className="c-navy fw-b fz-13 fz-14-md mb-4 mb-2-md">設定済みの宿泊日程</p>
                        <p className="fz-13 fz-14-md c-navy">
                          {planSelectFormData.hotel.map((hotelData, hIndex) => (
                            <Fragment key={hIndex}>
                              {toCircled(hIndex + 1)} {dateFormat(hotelData.checkIn, "YYYY年MM月DD日")} 〜 {dateFormat(hotelData.checkOut, "YYYY年MM月DD日")}
                              <br/>
                            </Fragment>
                          ))}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* step1 */}
                  {defaultStep === 1 ? (
                    <>
                      <StaySelectModalStep1
                        areaSelectOption={areaSelectOption}
                        defaultStep={defaultStep}
                        target={target}
                        selectedHotelSort={selectedHotelSort}
                        sortHotels={sortHotels}
                        loading={loading}
                        isListLoading={isListLoading}
                        planSelectFormData={planSelectFormData}
                        handleHotelDetail={handleHotelDetail}
                        isUpdate={isUpdate}
                        updateHotelIndex={updateHotelIndex}
                        checkIn={checkIn}
                        checkOut={checkOut}
                        onPageHandlePlanDetail={onPageHandlePlanDetail}
                        hotelAllData={hotelData}
                        hotelAreas={hotelAreas}
                        hotelTypes={hotelTypes}
                        hotelAreasFilterState={hotelAreasFilterState}
                        hotelTypesFilterState={hotelTypesFilterState}
                        handleHotelAreasFilter={handleHotelAreasFilter}
                        handleHotelTypesFilter={handleHotelTypesFilter}
                        ></StaySelectModalStep1>
                    </>
                  ) : defaultStep === 2 ? (
                    <>
                      {/* step2 */}
                      <StaySelectModalStep2
                        hotelCode={hotelCode}
                        defaultStep={defaultStep}
                        roomTypeFilter={roomTypeFilter}
                        mealFilter={mealFilter}
                        roomTypeFilterState={roomTypeFilterState}
                        handleRoomTypeFilter={handleRoomTypeFilter}
                        mealFilterState={mealFilterState}
                        handleMealFilter={handleMealFilter}
                        loading={loading}
                        planSelectFormData={planSelectFormData}
                        onPageHandle={onPageHandle}
                        selectedRoomSort={selectedRoomSort}
                        sortRoomPlan={sortRoomPlan}
                        hotelDetail={hotelDetail}
                        planExists={planExists}
                        roomBaxClass={roomBaxClass}
                        openRoomDetailModal={openRoomDetailModal}
                        roomData={roomData}
                        onClickPlandetailModal={onClickPlandetailModal}
                        isUpdate={isUpdate}
                        updateHotelIndex={updateHotelIndex}
                        checkIn={checkIn}
                        checkOut={checkOut}
                        callbackUpdateSelectHotel={callbackUpdateSelectHotel}
                        StayAccordionStateList={StayAccordionStateList}
                        handleStayAccordionList={handleStayAccordionList}
                        isSelectedRooms={isSelectedRooms}
                        updateHotelDetail={updateHotelDetail}
                        setDefaultHotelDetailData={setDefaultHotelDetailData}
                        hotelRoomAllController={hotelRoomAllAbortController}></StaySelectModalStep2>
                    </>
                  ):(<></>)}

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* モーダル：ホテル詳細 */}
      <HotelHandModal
        isShow={hotelHandModalState}
        callback={handleHotelHandModal}
        isUpdate={isUpdate}
        updateIndex={updateHotelIndex}
        hotelList={hotelList}
        planSelectFormData={planSelectFormData}
        selectHotel={onPageModalHandlePlanDetail}
      />

      {/* モーダル：プラン詳細 */}
      <PlanDetailModal
        planSelectFormData={planSelectFormData}
        isUpdate={isUpdate}
        updateIndex={updateHotelIndex}
        isShow={PlanDetailModalState}
        updateSelectHotel={callbackUpdateSelectHotel}
        callback={handlPlandetailModal}
        planData={planData}
        roomData={roomData}
      />

      {/* モーダル：客室詳細 */}
      <RoomDetailModal
        isShow={roomDetailModalState}
        roomData={roomData}
        callback={handleRoomDetailModal}
      />
    </>
  );
};
export default MyStepModal;
